import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Box } from '@core';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const AnimatedItem = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid;
  border-color: ${({ theme }) => `${theme.colors.primary} transparent transparent transparent`};
  border-radius: 50%;
  animation-name: ${rotate360};
  animation-duration: 1200ms;
  animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
  animation-iteration-count: infinite;
  animation-delay: ${({ animationDelay }) => animationDelay && `${animationDelay}ms`};
`;

const Loader = ({ isLoading }) => {
  return isLoading ? (
    <Box as="span" display="inline-block" position="relative" width={40} height={40}>
      <AnimatedItem animationDelay={-450} />
      <AnimatedItem animationDelay={-300} />
      <AnimatedItem animationDelay={-150} />
      <AnimatedItem />
    </Box>
  ) : null;
};

export default Loader;
